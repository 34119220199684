import styled from "styled-components"

export const PillWrapper = styled.div`
  border: solid 1px ${props => props.theme.colors.darkBlue};
  border-radius: 30px;
  margin: 7px;
`
export const PillText = styled.div`
  padding: 5px 15px 5px 15px;
  color: ${props => props.theme.colors.darkBlue};
  font-size: ${props => props.theme.fontSizes.verySmall};
  font-weight: ${props => props.theme.fontWeight.bold};
`
