import React from "react"
import Project from "../../UI/Project"
import projectsData from "../../../data/components/projects"
import { Container, DoubleGrid } from "./style"
import { H2Title, OrangeSpan, Section } from "../../../globalStyle"
import useWorkQuery from "../../../helpers/queries/ourWork"

const OurWork = () => {
  const ourWorkData = useWorkQuery()

  return (
    <Section>
      <Container>
        <H2Title center={true}>
          Explore <OrangeSpan>our work</OrangeSpan>
        </H2Title>
        <DoubleGrid>
          {projectsData.map(p => (
            <Project
              key={p.id}
              data={p}
              image={
                ourWorkData.allFile.edges[p.id - 1].node.childImageSharp
                  .gatsbyImageData
              }
            />
          ))}
        </DoubleGrid>
      </Container>
    </Section>
  )
}

export default OurWork
