import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;

  flex-direction: column;
  height: 100%;
`
