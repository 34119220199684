import React from "react"
import { Icon } from "@iconify/react"
import arrowRight from "@iconify-icons/bi/arrow-right"
import { Row, Column, CardTitle, ImageWrapper, Link, Wrapper } from "./style"
import { P } from "../../../globalStyle"
import IWhatWeDoCard from "./types"
import { Link as GatsbyLink } from "gatsby"

const WhatWeDoCard = (props: IWhatWeDoCard) => {
  const { title, image, description, slug } = props.data
  return (
    <GatsbyLink to={slug}>
      <Wrapper>
        <ImageWrapper>
          <Icon icon={image}></Icon>
        </ImageWrapper>
        <Column>
          <CardTitle>{title}</CardTitle>

          <P>{description}</P>
          <Row>
            <Link to={slug}>Read more</Link>
            <Icon icon={arrowRight} />
          </Row>
        </Column>
      </Wrapper>
    </GatsbyLink>
  )
}

export default WhatWeDoCard
