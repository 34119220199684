import React from "react"
import {
  Section,
  H2Title,
  P,
  OrangeSpan,
  AnimatedBackground,
} from "../../../globalStyle"
import {
  DoubleFlex,
  Wrapper,
  ImageCover,
  ContainerLeft,
  ContainerRight,
} from "./style"
import ButtonArrow from "../../UI/ButtonArrow"
import useTalentsForgeQuery from "../../../helpers/queries/talentForge"
import { useTheme } from "styled-components"
import { InlineIcon } from "@iconify/react"
import anvilIcon from "@iconify-icons/mdi/anvil"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const TalentForge = () => {
  const talentsForgeData = useTalentsForgeQuery()
  const theme = useTheme()
  const bgImage = convertToBgImage(
    talentsForgeData.allFile.edges[0].node.childImageSharp.gatsbyImageData
  )
  return (
    <Section>
      <DoubleFlex>
        <ContainerLeft>
          <AnimatedBackground />
          <Wrapper>
            <H2Title color={theme.colors.white}>Talents Forge</H2Title>
            <P
              style={{ margin: "2px 0 2px 0" }}
              center={true}
              color={theme.colors.white}
            >
              We share knowledge about software development
            </P>
            <H2Title color={theme.colors.white}>
              <OrangeSpan>{"{"} </OrangeSpan>
              <InlineIcon icon={anvilIcon} /> <OrangeSpan>{"}"}</OrangeSpan> oak
              <OrangeSpan>forge</OrangeSpan>
            </H2Title>
          </Wrapper>
        </ContainerLeft>
        <ContainerRight>
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
          >
            <ImageCover />
            <Wrapper>
              <ButtonArrow
                hoverColor={theme.colors.white}
                backgroundColor={theme.colors.orange}
                color={theme.colors.white}
                slug="/talents-forge/"
              >
                Start An Oak Adventure
              </ButtonArrow>
            </Wrapper>
          </BackgroundImage>
        </ContainerRight>
      </DoubleFlex>
    </Section>
  )
}

export default TalentForge
