import React from "react"
import { Wrapper } from "./style"
import ButtonArrow from "../../ButtonArrow"
import { H3Title, H2Title, P, OrangeSpan } from "../../../../globalStyle"
import { useTheme } from "styled-components"
import IProject from "../types"
import { Link } from "gatsby"

const SectionOne = (props: IProject) => {
  const theme = useTheme()
  const { title, description_1, description_2, slug } = props.data
  const { isInService } = props
  return (
    <Wrapper>
      {isInService ? (
        <H2Title>
          We turn words <OrangeSpan>into code</OrangeSpan>
        </H2Title>
      ) : (
        <></>
      )}
      <H3Title isDarkBlue={true}>{title}</H3Title>
      <P lineHeight={theme.lineHeight.average}>{description_1}</P>
      <P>{description_2}</P>
      <div>
        <ButtonArrow
          backgroundColor="white"
          hoverColor="white"
          color="#21375d"
          slug={slug}
        >
          <span>{title}</span>
        </ButtonArrow>{" "}
        {!isInService && (
          <ButtonArrow
            backgroundColor="#f17313"
            hoverColor="white"
            color="white"
            slug={"case-studies"}
          >
            Find out more case studies
          </ButtonArrow>
        )}
      </div>
    </Wrapper>
  )
}

export default SectionOne
