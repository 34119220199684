import styled from "styled-components"

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`
export const Row = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  & > svg {
    transition: 0.3s;
  }
  &:hover {
    & > span,
    svg {
      color: ${props => props.theme.colors.orange};
    }
  }
`
export const CardTitle = styled.h3`
  font-size: ${props => props.theme.fontSizes.medium};
  margin: 0;
  color: ${props => props.theme.colors.darkBlue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.orange};
  }
  @media (max-width: 640px) {
    font-size: ${props => props.theme.fontSizes.average};
    & > svg {
      padding: 0px;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 128px;
    height: 128px;
    color: ${props => props.theme.colors.orange};
    padding: 30px;
  }
  @media (max-width: 640px) {
    & > svg {
      padding: 0px;
    }
  }
`

export const Link = styled.span`
  color: ${props => props.theme.colors.darkBlue};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeight.bold};
  transition: 0.3s;
`
