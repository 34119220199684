import styled from "styled-components"
import { Icon } from "@iconify/react"
import { IShape, IButtonText } from "./types"

export const Wrapper = styled.div`
  display: inline-block;
`

export const Shape = styled.div<IShape>`
  margin: 0 0 15px 0;
  overflow: hidden;
  background-color: ${props => props.backgroundColor};
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  z-index: 460;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.color};
  &:hover {
    & > div:nth-child(1) {
      transform: scale(1.3);
      border-radius: 300px;
    }
    & > span {
      transform: translateX(-10px);
      color: ${props => props.hoverColor};
    }

    & > div:nth-child(3) {
      transform: translateX(-65px);
    }
  }
`
export const Animation = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  transform: scale(0);
  background-color: ${props => props.theme.colors.darkBlue};
  border-radius: 0;
`

export const ButtonText = styled.span<IButtonText>`
  font-size: 1.125rem;
  padding-right: 10px;
  z-index: 465;
  transition: 0.3s;
  color: ${props => props.color};
`

export const ButtonIcon = styled(Icon)`
  z-index: 465;
`
export const IconWrapper = styled.div`
  right: -50px;
  position: absolute;
  display: block;
  transition: 0.3s;
`
