import React, { PropsWithChildren } from "react"
import { Link } from "gatsby"
import {
  Wrapper,
  Shape,
  Animation,
  ButtonText,
  ButtonIcon,
  IconWrapper,
} from "./style"
import arrowRight from "@iconify-icons/bi/arrow-right"
import IButtonArrow from "./types"

const ButtonArrow = (props: PropsWithChildren<IButtonArrow>) => {
  const { children, slug, color, backgroundColor, hoverColor } = props
  return (
    <Wrapper>
      {slug ? (
        <Link to={slug}>
          <Shape
            color={color}
            hoverColor={hoverColor}
            backgroundColor={backgroundColor}
          >
            <Animation />
            <ButtonText color={color}>{children}</ButtonText>
            <IconWrapper>
              <ButtonIcon height={22} icon={arrowRight} />
            </IconWrapper>
          </Shape>
        </Link>
      ) : (
        <Shape
          color={color}
          hoverColor={hoverColor}
          backgroundColor={backgroundColor}
        >
          <Animation />
          <ButtonText color={color}>{children}</ButtonText>
          <IconWrapper>
            <ButtonIcon height={22} icon={arrowRight} />
          </IconWrapper>
        </Shape>
      )}
    </Wrapper>
  )
}

export default ButtonArrow
