import React from "react"
import { Background } from "./style"
import { GatsbyImage } from "gatsby-plugin-image"
import IProject from "../types"

const SectionTwo = (props: IProject) => {
  const { image } = props
  const { title, backgroundColor } = props.data
  return (
    <>
      <Background backgroundColor={backgroundColor}>
        <GatsbyImage image={image} alt={title} />
      </Background>
    </>
  )
}

export default SectionTwo
