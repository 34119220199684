import React, { useEffect, useState } from "react"
import Layout from "../components/UI/Layout"
import Landing from "../components/Sections/Landing"
import Head from "../components/Head"
import WhatWeDo from "../components/Sections/WhatWeDo"
import Technology from "../components/Sections/Technology"
import OurWork from "../components/Sections/OurWork"
import Companies from "../components/Sections/Companies"
import QuickContact from "../components/Sections/QuickContact"
import TalentForge from "../components/Sections/TalentsForge"
import Button from "../components/UI/Button"
import { OrangeSpan } from "../globalStyle"
import useIndexCompaniesQuery from "../helpers/queries/webAppDevelopmentQuery"

const Index = () => {
  const companies = useIndexCompaniesQuery()
  return (
    <Layout>
      <Head
        description="Agile solutions for companies and startups 💻 We provide high-quality software development & scrum teams on-site or remotely. Estimate your project for free ✅"
        pageTitle="Software House"
        keywords="software, oakfusion"
      />
      <Landing />
      <WhatWeDo />
      <Technology />
      <OurWork />
      <Companies companies={companies} />
      <TalentForge />
      <QuickContact
        title={
          <>
            Got other <OrangeSpan>ideas?</OrangeSpan>
          </>
        }
        text={"Make it happen"}
        button={<Button slug="/contact">Contact</Button>}
      />
    </Layout>
  )
}

export default Index
