import styled from "styled-components"

export const Container = styled.div`
  margin: 50px 0 50px 0;
  width: 1470px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const DoubleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    ". ."
    ". .";
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "."
      "."
      "."
      ".";
  }
`
