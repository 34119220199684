import monitorIcon from "@iconify-icons/ph/monitor"
import groupIcon from "@iconify-icons/cil/group"
import codeIcon from "@iconify-icons/bytesize/code"
import pencilRuler from "@iconify-icons/la/pencil-ruler"

const whatWeDoData = [
  {
    id: 1,
    title: "Web App Development",
    image: monitorIcon,
    slug: "/web-app-development/",
    description:
      "Scale up your business and fulfill client’s needs with top-notch business software solutions.",
  },
  {
    id: 2,
    title: "Solutions For Startups",
    image: pencilRuler,
    slug: "/solutions-for-startups/",
    description:
      "We create dedicated software for startups and deliver startup product’s within 30 days.",
  },
  {
    id: 3,
    title: "Digital Transformation",
    image: codeIcon,
    slug: "/digital-transformation/",
    description:
      "Thinking about optimizing your business with cutting edge technologies? Look no further.",
  },
  {
    id: 4,
    title: "Team Leasing",
    image: groupIcon,
    slug: "/team-leasing/",
    description:
      "Rent best of our specialists to aid your team with development work.",
  },
]

export default whatWeDoData
