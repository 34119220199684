import React from "react"
import WhatWeDoCard from "../../UI/WhatWeDoCard"
import whatWeDoData from "../../../data/components/whatWeDo"
import { Container } from "./style"
import { H2Title, OrangeSpan, Section, DoubleGrid } from "../../../globalStyle"

const WhatWeDo = () => {
  return (
    <Section>
      <Container>
        <H2Title center={true}>
          What <OrangeSpan>we do</OrangeSpan>
        </H2Title>
        <DoubleGrid>
          {whatWeDoData.map(c => (
            <WhatWeDoCard key={c.id} data={c} />
          ))}
        </DoubleGrid>
      </Container>
    </Section>
  )
}

export default WhatWeDo
