import React from "react"
import { Container, PillsWrapper } from "./style"
import { H2Title, OrangeSpan, Section, P } from "../../../globalStyle"
import Pill from "../../UI/Pill"
import pillsData from "../../../data/components/pills"

const Technology = () => {
  return (
    <Section>
      <Container>
        <H2Title center={true}>
          Development <OrangeSpan>technology</OrangeSpan>
        </H2Title>
        <P>
          We live and breathe Java Fullstack Development but we don’t limit
          ourselves just to Spring Framework, Angular and React. Below all tech
          stack we are proficient in:
        </P>
        <PillsWrapper>
          {pillsData.map(p => (
            <Pill key={p.id} id={p.id} text={p.text} />
          ))}
        </PillsWrapper>
      </Container>
    </Section>
  )
}

export default Technology
