import styled, { keyframes } from "styled-components"
import { AnimatedBackground } from "../../../globalStyle"

const AnimBounce = keyframes`
0% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(-20%);
  }
`

export const LandingBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(360deg, #3161b4 0%, #21375d 72%);
`

export const AnimatedLanding = styled(AnimatedBackground)`
  z-index: 505;
`

export const LandingWrapper = styled.div`
  min-height: 570px;
  min-width: 255px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
  & > p {
    z-index: 508;
  }
`

export const Title = styled.h1`
  z-index: 508;
  display: flex;
  color: white;
  font-size: ${props => props.theme.fontSizes.big};
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 430px) {
    font-size: ${props => props.theme.fontSizes.huge};
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  z-index: 600;
`
export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  z-index: 508;
  & > a > div {
    animation: ${AnimBounce} 3s infinite;
    filter: drop-shadow(0px 8px 3px #00000030);
  }
  @media (max-height: 780px) {
    bottom: 0px;
  }
`
