import { useStaticQuery, graphql } from "gatsby"

const useWorkQuery = () => {
  const ourWorkQuery = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "png" }
          name: { in: ["godzinna", "immobilenscout24-mockup", "Click4fit-1"] }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return ourWorkQuery
}

export default useWorkQuery
