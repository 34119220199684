import React from "react"
import Button from "../../UI/Button"
import { StaticImage } from "gatsby-plugin-image"
import {
  AnimatedLanding,
  LandingWrapper,
  Title,
  ButtonWrapper,
  ImageWrapper,
} from "./style"
import { Section, P } from "../../../globalStyle"
import { useTheme } from "styled-components"

const Landing = () => {
  const theme = useTheme()
  return (
    <Section>
      <AnimatedLanding />
      <LandingWrapper>
        <Title>We create software solutions</Title>
        <P
          center={true}
          style={{ margin: "2px 0 2px 0" }}
          color={theme.colors.white}
        >
          Robust software for business
        </P>
        <P
          center={true}
          style={{ margin: "2px 0 2px 0" }}
          color={theme.colors.white}
        >
          We provide companies with innovative software solutions.
        </P>
        <ButtonWrapper>
          <Button slug="/contact">Contact</Button>
        </ButtonWrapper>

        <ImageWrapper>
          <a href="https://clutch.co/profile/oakfusion">
            <StaticImage
              src="../../../data/images/clutch-oxywi1p79x1qz1yejcbrxdodgiucpyt8s4cwkyoa3c.png"
              alt="clutch"
              placeholder="tracedSVG"
            />
          </a>
        </ImageWrapper>
      </LandingWrapper>
    </Section>
  )
}

export default Landing
