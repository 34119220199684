import styled from "styled-components"

export const Container = styled.div`
  margin: 50px 0 50px 0;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 700px;
`

export const PillsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`
