const pillsData = [
  {
    id: 1,
    text: "JAVA",
  },
  {
    id: 2,
    text: "K8S",
  },
  {
    id: 3,
    text: "DOCKER",
  },
  {
    id: 4,
    text: "ANGULAR",
  },
  {
    id: 5,
    text: "REACT",
  },
  {
    id: 6,
    text: "ANDROID",
  },
  {
    id: 7,
    text: "IOS",
  },
  {
    id: 8,
    text: "IOT",
  },
  {
    id: 9,
    text: "AMAZON",
  },
  {
    id: 10,
    text: ".NET",
  },
  {
    id: 11,
    text: "ELASTIC",
  },
]

export default pillsData
