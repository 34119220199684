import styled from "styled-components"

export const DoubleFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`
export const ContainerLeft = styled.div`
  position: relative;
  overflow: hidden;
  width: 50%;
  //background-color: ${props => props.theme.colors.darkBlue};
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ContainerRight = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  height: 400px;
  & > h2,
  p {
    z-index: 465;
  }
`
export const ImageWrapper = styled.div``

export const ImageCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.darkBlue};
  opacity: 0.6;
`
