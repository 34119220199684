import styled from "styled-components"
import { IBackgroundProps } from "./types"

export const Background = styled.div<IBackgroundProps>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageWrapper = styled.div`
  & > img {
    width: 100%;
    height: 100%;
  }
`
