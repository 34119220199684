import { useStaticQuery, graphql } from "gatsby"

const useTalentsForgeQuery = () => {
  const talentsForgeQuery = useStaticQuery(graphql`
    query talentsForgeQuery {
      allFile(
        filter: {
          extension: { eq: "jpg" }
          name: { in: ["A7S03665_Easy-Resize.com_"] }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return talentsForgeQuery
}
export default useTalentsForgeQuery
