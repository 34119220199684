import immobilenScout24 from "../../data/images/products/immobilenscout24-mockup.png"
import click4fit from "../../data/images/products/Click4fit-1.png"
import godzinna from "../../data/images/products/godzinna.png"

const whatWeDoData = [
  {
    id: 1,
    title: "Click4fit",
    image: click4fit,
    slug: "/products/click4fit/",
    description_1:
      "Keeping up with the training schedule during COVID-19 hasn’t been the easiest task. Gyms were locked, traningi with other people was forbidden and the only way was to work out alone at home with no guidance. That is how Click4Fit has risen.",
    description_2:
      "We have managed to create a platform that customers can easily navigate through and find what they are looking for. In this case, this is an easy logging option with google and facebook account and simple but efficient interface with calendar, trening options and payments systems.",
    backgroundColor: "#f17313",
  },
  {
    id: 2,
    title: "Godzinna",
    image: godzinna,
    slug: "/products/godzinna/",
    description_1:
      "Godzinna is software for keeping the hours log in order. It is an web application dedicated for software companies with a user-friendly interface. In Godzinna, workflow is simple. You choose the name of your project and when record how many hours daily you’ve spent on it. ",
    description_2: "Done? That’s it, your hours are logged.",
    backgroundColor: "#21375d",
  },
  {
    id: 3,
    title: "Immobilen Scout24",
    image: immobilenScout24,
    slug: "/case-studies/immobilien-scout24/",
    description_1:
      "ImmobilienScout24 (IS24) is part of the business that is responsible for property services. It is the biggest estate portal in Germany that offers apartments and houses both for rent and for sale. With more than 10 million visitors per month, ImmobilienScout24 assists by providing a variety of services and innovative products to their clients and helps users find their dream home.",
    description_2:
      "We had started and built this application from scratch, successfully implementing all functions.",
    backgroundColor: "#21375d",
  },
]

export default whatWeDoData
