import React from "react"
import SectionOne from "./SectionOne"
import SectionTwo from "./SectionTwo"
import { useState, useEffect } from "react"
import IProject from "./types"

const Project = (props: IProject) => {
  const { id } = props.data
  const { image, isInService } = props

  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      {windowWidth > 1150 && id % 2 === 0 ? (
        <>
          <SectionTwo data={props.data} image={image}></SectionTwo>
          <SectionOne data={props.data} isInService={isInService}></SectionOne>
        </>
      ) : (
        <>
          <SectionOne data={props.data} isInService={isInService}></SectionOne>
          <SectionTwo data={props.data} image={image}></SectionTwo>
        </>
      )}
    </>
  )
}

export default Project
