import React from "react"
import { PillWrapper, PillText } from "./style"
import IPill from "./types"

const Pill = (props: IPill) => {
  const { text } = props
  return (
    <PillWrapper>
      <PillText>{text}</PillText>
    </PillWrapper>
  )
}

export default Pill
